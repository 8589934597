
import {
  filterDOMProps,
  useFallbackRef
} from '@diallink-corp/convergo-aria-utils';
import { useSlotProps } from '@diallink-corp/convergo-react-layout';
import { Text, TextProps } from '@diallink-corp/convergo-react-text';
import {
  Link as RemixLink,
  LinkProps as RemixLinkProps
} from '@remix-run/react';
import { forwardRef, RefObject } from 'react';

export interface LinkProps extends RemixLinkProps, TextProps {}

/**
 * Links placed in a text allow users to navigate to a different location. They can be presented
 * inline inside a paragraph or as standalone text.
 */
export const TextLink = forwardRef(
  (props: LinkProps, ref: RefObject<HTMLAnchorElement>) => {
    props = useSlotProps('link', props);
    const { className, children, to, size, weight, isHidden, ...otherProps } =
      props;

    ref = useFallbackRef(ref);

    if (typeof to === 'string' && isAbsolute(to)) {
      return (
        <Text size={size} weight={weight} isHidden={isHidden}>
          <a {...otherProps} href={to} className={className}>
            {children}
          </a>
        </Text>
      );
    }

    return (
      <Text size={size} weight={weight} isHidden={isHidden}>
        <RemixLink
          {...filterDOMProps(otherProps)}
          to={to}
          ref={ref}
          className={className}
        >
          {children}
        </RemixLink>
      </Text>
    );
  }
);

function isAbsolute(url: string) {
  return ['http', 'https', 'mailto', 'tel'].some((prefix) =>
    url.startsWith(`${prefix}:`)
  );
}
